.pointer {
  cursor: pointer;
}

div.thumbnail {
  width: 122px;
  height: 170px;
  cursor: pointer;
  background-color: white;
}
img.thumbnail {
  width: 120px;
  max-height: 170px;
  background-color: white;
}

.bg-secondary-dark {
  background: #33363a !important;
}


.google-map-wrapper {
  -webkit-transition: height 0.5s ease;
  -moz-transition: height 0.5s ease;
  -o-transition: height 0.5s ease;
  transition: height 0.5s ease;
  overflow: hidden;
}
.google-map-wrapper {
  height:400px;
  overflow:auto;
  background: #F3F1ED;
  position: relative;
}
.google-map-wrapper.for-messages{
  margin-bottom: 100px;
}
.google-map-container {
  height: 300px;
}